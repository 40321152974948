import Vue from 'vue'
import Vuex from 'vuex'
import api from '../../src/services/api'
import patchNotes from '../data/patchNotesDetails'

Vue.use(Vuex)

function roundOffValueOf(number) {
  return Number.isInteger(number) ? number : Number(number).toFixed(3)
}
export default new Vuex.Store({
  state: {
    componentData: [
      // this is 0th index
      {
        inputDict: {
          age: 65,
          riskModel: 'CN',
          dosYearModel: 'v24_2022',
          gender: 'm',
          ore: 0,
          baseRate: 800,
          calculationYear: 2022,
          DOB: null,
          commandType: 'member',
          plate: 'S',
          enrollmentDuration: '11',
          memberProfileConditions: [],
          baseConditions: [],
          newConditions: [],
        },
        isSubmitButtonClicked: false,
        responseData: {
          data: {},
        },
        compareCodecalculationDict: {},
      },
    ],
    APIErrorFlag: false,
    v05Data: [
      'v08_2024',
      'v08_2023',
      'v08_2022',
      'v05_2021',
      'v05_2020',
      'v05_2019',
      'v05_2018',
      'v05_2017',
    ],
    vData: [
      'v28_2024',
      'v24_2024',
      'v28_2023',
      'v24_2023',
      'v24_2022',
      'v24_2021',
      'v24_2020',
      'v24_2019',
      'v23_2018',
      'v22_2017',
    ],
    hhsData: [
      'hhs_v07_2024',
      'hhs_v07_2023',
      'hhs_v07_2022',
      'hhs_v05_2021',
      'hhs_v05_2020',
      'hhs_v05_2019',
      'hhs_v05_2018',
      'hhs_v05_2017',
    ],
    v21_esrd_data: ['esrd_v21_2020', 'esrd_v21_2021'],
    v24_esrd_data: ['esrd_v24_2022', 'esrd_v24_2023', 'esrd_v24_2024'],
    isPatchNotesModal:
      true &&
      (localStorage.getItem('removePatch') != 'true' ||
        localStorage.getItem('noOfPatchNotes') != patchNotes.length),
    isCmsModal: false,
    topMargin: '46px',
    search_member: [],
    search_member1: [],
    search_box: '',
    search_suggestion_loader: false,
    orec_disabled: false,
  },
  mutations: {
    updateStateData(state, payload) {
      let index = payload.index
      let dataDict = payload.data
      state.componentData[index].inputDict[dataDict.name] = dataDict.value
      if (dataDict.name == 'commandType') {
        state.componentData[index].responseData.data = {}
      }
      state.componentData[index].isSubmitButtonClicked = false
    },
    updateResponseData(state, responsePayload) {
      let index = responsePayload.index
      let response = responsePayload.apiResponse
      let current = state.componentData[index]
      current.isSubmitButtonClicked = true
      current.responseData = response
      let summary = response.data.Summary
      let command = current.inputDict.commandType
      let calculationDict = current.compareCodecalculationDict
      let totalRaf = 0
      let totalPremium = 0

      function getRafPremium(key1, key2, calulation) {
        let num1 = summary[key1][calulation]
        let num2 = summary[key2][calulation]
        num1 = isNaN(num1) ? 0 : num1
        num2 = isNaN(num2) ? 0 : num2
        return num1 + num2
      }
      if (command == 'compare') {
        let incrementRaf = getRafPremium('adds', 'upgrades', 'raf_change')
        calculationDict['incrementRaf'] = roundOffValueOf(incrementRaf)
        let gapsRaf = getRafPremium('deletes', 'downgrades', 'raf_change')
        calculationDict['gapsRaf'] = roundOffValueOf(gapsRaf)
        let diffRaf =
          Number(calculationDict['incrementRaf']) +
          Number(calculationDict['gapsRaf'])
        calculationDict['diffRaf'] = roundOffValueOf(diffRaf)
        calculationDict['incrementPremium'] = getRafPremium(
          'adds',
          'upgrades',
          'premium'
        )
        calculationDict['gapsPremium'] = getRafPremium(
          'deletes',
          'downgrades',
          'premium'
        )
        calculationDict['diffPremium'] =
          calculationDict['incrementPremium'] + calculationDict['gapsPremium']
        for (let key in summary) {
          totalRaf += !isNaN(summary[key].raf_change)
            ? summary[key].raf_change
            : 0
        }
        for (let key in summary) {
          totalPremium += !isNaN(summary[key].premium)
            ? summary[key].premium
            : 0
        }
        calculationDict['totalRafChange'] = Number(totalRaf).toFixed(3)
        calculationDict['totalPremium'] = totalPremium
        current.compareCodecalculationDict = calculationDict
      }
    },
    addRowToComponentData(state, payload) {
      //add new row to component data list
      // use index of previous row to make new row with default input values
      let current = state.componentData[payload.index].inputDict
      state.componentData.push({
        inputDict: {
          age: current.age,
          riskModel: current.riskModel,
          dosYearModel: current.dosYearModel,
          gender: current.gender,
          ore: current.ore,
          baseRate: current.baseRate,
          calculationYear: current.calculationYear,
          DOB: current.DOB,
          plate: current.plate,
          enrollmentDuration: current.enrollmentDuration,
          commandType: 'member',
          memberProfileConditions: [],
          baseConditions: [],
          newConditions: [],
        },
        isSubmitButtonClicked: false,
        responseData: {
          data: {},
        },
        compareCodecalculationDict: {},
      })
    },
    deleteRowFromComponentData(state, payload) {
      //delete specified row from component data list
      state.componentData.splice(payload.index, 1)
    },
    clearResult(state, payload) {
      let current = state.componentData[payload.index]
      //check length of componentData list, if it's greater than current index then delete
      current.responseData.data = {}
      current.inputDict = {
        commandType: payload.commandType,
        age: 65,
        riskModel: 'CN',
        dosYearModel: 'v24_2022',
        gender: 'm',
        ore: 0,
        baseRate: 800,
        calculationYear: null,
        DOB: null,
        plate: 'S',
        enrollmentDuration: '11',
        memberProfileConditions: [],
        baseConditions: [],
        newConditions: [],
      }
      current.compareCodecalculationDict = {}
      current.isSubmitButtonClicked = false
      if (
        state.componentData.length - 1 > payload.index &&
        state.componentData[payload.index + 1].inputDict.commandType == 'pick'
      ) {
        // if next row is pick command then clear it
        state.componentData.splice(payload.index + 1, 1)
        current.isSubmitButtonClicked = false
      }
    },
    setAPIErrorFlag(state, errorFlag) {
      state.APIErrorFlag = errorFlag
    },
    togglePatchNotesModal(state) {
      state.isPatchNotesModal = !state.isPatchNotesModal
    },
    updateCmsModal(state) {
      state.isCmsModal = !state.isCmsModal
    },
    removeCmsModal(state) {
      state.isCmsModal = false
    },
    setSubmitButtonToClicked(state, payload) {
      state.componentData[payload.index].isSubmitButtonClicked = true
    },
  },
  actions: {
    updateStateData(context, payload) {
      context.commit('updateStateData', payload)
    },
    deleteRow(context, payload) {
      context.commit('deleteRowFromComponentData', payload)
    },
    handleSubmitRequest(context, payload) {
      context.commit('setAPIErrorFlag', false)
      context.commit('setSubmitButtonToclicked', payload)
      let index = payload.index
      let current = this.state.componentData[index].inputDict
      let APIParameters = {
        ver: current.dosYearModel,
        model: current.riskModel,
        age: current.age,
        baserate: current.baseRate,
        calculationYear: current.calculationYear,
        enrollmentDuration: current.enrollmentDuration,
        plate: current.plate,
        DOB: current.DOB,
      }
      if (current.commandType == 'member') {
        let memberAPIParameter = Object.assign(APIParameters, {
          condition_list: current.memberProfileConditions,
          sex: current.gender,
          orec: current.ore,
        })
        api
          .getMemberProfileResult(memberAPIParameter)
          .then((response) => {
            let responsePayload = {
              index: payload.index,
              apiResponse: response,
            }
            context.commit('updateResponseData', responsePayload)
          })
          .catch(function() {
            // error log to sentry will go here.
            context.commit('setAPIErrorFlag', true)
          })
      } else if (current.commandType == 'compare') {
        let compareCodeAPIParameter = Object.assign(APIParameters, {
          old_list: current.baseConditions,
          new_list: current.newConditions,
          gender: current.gender,
          DOB: current.DOB,
        })
        api
          .getCompareCodeResult(compareCodeAPIParameter)
          .then((response) => {
            let responsePayload = {
              index: payload.index,
              apiResponse: response,
            }
            context.commit('updateResponseData', responsePayload)
          })
          .catch(function() {
            // error log to sentry will go here.
            context.commit('setAPIErrorFlag', true)
          })
      }
    },
    addRowToComponentData(context, payload) {
      context.commit('addRowToComponentData', payload)
    },
    clearResult(context, payload) {
      context.commit('clearResult', payload)
    },
  },
  getters: {
    getComponentDataByIndex: (state) => (componentDataindex) => {
      return state.componentData[componentDataindex]
    },
    hasHhsData: (state) => (dosVersion) => {
      return state.hhsData.includes(dosVersion)
    },
    hasVData: (state) => (dosVersion) => {
      return state.vData.includes(dosVersion)
    },
    hasV05Data: (state) => (dosVersion) => {
      return state.v05Data.includes(dosVersion)
    },
    hasv21ESRDData: (state) => (dosVersion) => {
      return state.v21_esrd_data.includes(dosVersion)
    },
    hasv24ESRDData: (state) => (dosVersion) => {
      return state.v24_esrd_data.includes(dosVersion)
    },
    hccCodingValue: () => (codingValue) => {
      return codingValue == 'hcc'
    },
    dxCodingValue: () => (codingValue) => {
      return codingValue == 'dx'
    },
  },
  modules: {},
})
